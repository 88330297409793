<template>
  <div @mousewheel="goWheel($event)">
    <el-carousel :style="{height:swiperHeight}" ref="swiper" indicator-position="none" @change="change" :autoplay="false" :loop="false" direction="vertical" @mousewheel="goWheel($event)" class="download">
      <el-carousel-item class="download-top" :style="{height:swiperHeight}" v-lazy:background-image="top">
        <!-- <img class="download-top-bg" src="http://testshop.ichongapp.com/download/top.png" alt=""> -->
        <div class="download-top-top">
          <img v-lazy="text" alt="">
        </div>
        <!-- <div class="download-top-bottom" @mouseleave="downloadLeave"> -->
        <div class="download-top-bottom">
          <div class="download-top-bottom-top">
            <div class="download-top-bottom-top-item" v-for="(item,index) in downloadList" :key="index" @click="downloadClick(item.title)">
              <div class="download-top-bottom-top-item-top">
                <img :src="item.img" alt="">
                <p>{{ item.title }}</p>
              </div>
              <div v-if="downloadtitle==item.title" class="download-top-bottom-top-item-bottom"></div>
            </div>
          </div>

          <div class="download-top-bottom-content">
            <div class="download-top-bottom-content-left">
              <img src="../../assets/home/MiniCode.png" v-if="downloadtitle=='小程序' || downloadtitle==''" alt="">
              <!-- <img :src="code" v-else alt=""> -->
              <img :src="androidDownLoadCode" v-else alt="">
              <!-- <img :src="androidDownLoadCode" v-if="downloadtitle=='安卓下载' || downloadtitle==''" alt="">
              <img :src="iosDownLoadCode" v-if="downloadtitle=='ios下载' || downloadtitle==''" alt=""> -->
            </div>
            <div class="download-top-bottom-content-right">
              <p>扫描二维码</p>
              <p v-if="downloadtitle=='小程序' || downloadtitle==''">进入微信小程序</p>
              <p v-else>下载述宠app</p>
            </div>
          </div>

          <!-- <div class="download-top-bottom-bottom">
            <div class="download-top-bottom-bottom-left">
              <img :src="$imgUrl+'home/code.png'" alt="" srcset="">
            </div>
            <div class="download-top-bottom-bottom-right">
              <p>扫描二维码
              </p>
              <p v-if="downloadtitle=='小程序'">进入微信小程序</p>
              <p v-else>下载述宠app</p>
            </div>
          </div> -->

        </div>

        <!-- <div class="download-top-center">
          <div class="Mini">
            <img :src="Mini" alt="">
            <span>小程序</span>
            <span class="hover-line"></span>
            <div class="hover-code">
              <img :src="code" alt="">
            </div>
          </div>
          <div class="Apple">
            <img :src="Apple" alt="">
            <span>ios下载</span>
            <span class="hover-line"></span>
            <div class="hover-code">
              <img :src="code" alt="">
            </div>
          </div>
          <div class="Android">
            <img :src="Android" alt="">
            <span>安卓下载</span>
            <span class="hover-line"></span>
            <div class="hover-code">
              <img :src="code" alt="">
            </div>
          </div>
        </div> -->

        <!-- <div class="download-top-bottom1">
          <div class="download-top-bottom-bottom-left">
            <img src="../../assets/home/code.png" alt="" srcset="">
          </div>
          <div class="download-top-bottom-bottom-right">
            <p>扫描二维码
            </p>
            <p>下载述宠APP</p>
          </div>
        </div> -->
      </el-carousel-item>
    </el-carousel>
  </div>
</template>
<script>
export default {
  data() {
    return {
      swiperHeight1: document.body.clientHeight - 52 + 'px',
      swiperHeight: document.body.clientHeight - 52 + 'px',
      swiperWidth: document.body.clientWidth + 'px',
      swiperIndex: 0,
      one: true,//以此来控制每次轮播图切换的张数
      text: this.$imgUrl + 'download/text.png',
      // top: this.$imgUrl + 'download/top.png',
      top: require('../../assets/home/downloadBg.png'),
      downloadtitle: '小程序',
      Mini: require('../../assets/home/index/Mini.png'),
      Android: require('../../assets/home/index/Android.png'),
      Apple: require('../../assets/home/index/Apple.png'),
      // code: require('../../assets/home/code.png'),
      androidDownLoadCode: require('../../assets/home/index/androidDownLoadCode.png'),
      iosDownLoadCode: require('../../assets/home/index/iosDownLoadCode.png'),
      downloadList: [
        { img: this.$imgUrl + 'home/wx.png', title: '小程序', codeImg: '../../assets/home/index/Mini.png', bottomTitle: '扫描二维码', content: '进入微信小程序' },
        { img: this.$imgUrl + 'home/apple.png', title: 'ios下载', codeImg: '../../assets/home/index/Apple.png', bottomTitle: '扫描二维码', content: '下载述宠APP' },
        { img: this.$imgUrl + 'home/android.png', title: '安卓下载', codeImg: '../../assets/home/index/Android.png', bottomTitle: '扫描二维码', content: '下载述宠APP' },
      ],
    }
  },
  mounted() {
    const that = this
    window.onresize = () => {
      return (() => {
        window.fullHeight = document.documentElement.clientHeight;
        that.swiperHeight = (window.fullHeight - 52) + 'px'
      })()

    }
  },
  // watch:{
  //   swiperHeight:{
  //     deep:true,
  //     immediate:true,
  //     handler(newData){
  //       this.swiperHeight=newData
  //       // this.swiperHeight=this.swiperHeight1
  //     console.log("swiperHeight",this.swiperHeight)

  //     }
  //   }
  // },
  methods: {
    change(e) {
      this.swiperIndex = e
    },
    goWheel(event) {
      if (event.deltaY > 0 && this.one == true) {
        this.$refs.swiper.next();
        this.one = false;
        setTimeout(() => {
          this.one = true
        }, 500)
        if (this.swiperIndex == 0) {
          this.swiperIndex = 1
        }
        if (this.swiperIndex == 1) {
          this.$emit("goEnd", true)
        } else {
          this.$emit("goEnd", false)

        }
      }

      if (event.deltaY < 0 && this.one == true) {
        this.$refs.swiper.prev();
        this.one = false;
        setTimeout(() => {
          this.one = true
        }, 500)
        this.$emit("goEnd", false)
      }
    },
    downloadClick(downloadtitle) {
      if (this.downloadtitle != downloadtitle) {
        this.downloadtitle = downloadtitle
      }
    },
    downloadLeave() {
      // this.downloadtitle = ''
    }
  }
}
</script>
<style lang="scss" scoped>
img[lazy="loading"] {
  width: 400px !important;
  height: 50px !important;
}
/deep/ .el-carousel__container {
  height: 100% !important;
}
.download {
  // min-width: 1680px;
  // min-width: 1920px;
  .download-top {
    width: 100%;
    // background-image: url("http://testshop.ichongapp.com/download/top.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    // left: 50%;
    // transform: translate(-50%);
    // min-width: 1180px;
    // justify-content: center;
    // align-items: center;
    // .download-top-bg{
    //   position: absolute;
    //   top: 0px;
    //   bottom: 0px;
    //   left: 0px;
    //   right: 0px;
    //   width: 100%;
    //   height: 100%;
    // }

    // @media screen and (max-height: 758px) {
    //   .download-top-top {
    //     margin-top: 81px !important;
    //   }
    // }
    .download-top-top {
      margin-left: 51.56%;
      margin-top: 83PX;
      // text-align: left;
      img {
        width: 368px;
        height: 108px;
      }
    }

    .download-top-center {
      width: 470px;
      height: 54px;
      background-color: #fff;
      border-radius: 27px;
      margin-left: 51.56%;
      margin-top: 25px;
      display: flex;
      justify-content: space-around;
      align-items: center;

      .Mini,
      .Apple,
      .Android {
        display: flex;
        align-items: center;
        color: #7bc8d2;
        font-size: 16px;
        font-family: SourceHanSansCN-Medium;
        cursor: pointer;
        position: relative;

        img {
          width: 31px;
          height: 31px;
          margin-right: 5px;
        }
      }

      .Mini {
        margin-left: 55px;
      }

      .Android {
        margin-right: 55px;
      }

      .hover-line {
        display: none;
        width: 50px;
        height: 2px;
        background-color: #7bc8d2;
        border-radius: 10px;
        position: absolute;
        top: 120%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      .hover-code {
        display: none;
        position: absolute;
        top: -140px;
        right: -20px;
        width: 121px;
        height: 121px;
        border-radius: 14px;
        background-color: #fff;

        img {
          width: 102px;
          height: 102px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }

      .Mini:hover .hover-line,
      .Mini:hover .hover-code {
        display: block;
      }
      .Android:hover .hover-line,
      .Android:hover .hover-code {
        display: block;
      }
      .Apple:hover .hover-line,
      .Apple:hover .hover-code {
        display: block;
      }
    }

    .download-top-bottom {
      margin-top: 25px;
      display: flex;
      flex-direction: column;
      margin-left: 51.56%;
      position: relative;
      .download-top-bottom-bottom {
        height: 4px;
        background-color: #7bc8d2;
        position: absolute;
        top: 60px;
      }
      .download-top-bottom-top {
        background-color: #fff;
        width: 329px;
        height: 38px;
        border-radius: 27px;
        box-shadow: 0px 7px 26px 6px rgba(123, 200, 210, 0.18);
        display: flex;
        justify-content: space-around;
        align-items: center;
        .download-top-bottom-top-item {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          position: relative;
          cursor: pointer;
          .download-top-bottom-top-item-bottom {
            width: 100%;
            height: 3px;
            background-color: #7bc8d2;
            border-radius: 2px 2px 0px 0px;
            position: absolute;
            bottom: 0px;
            left: 0;
            right: 0;
          }
          .download-top-bottom-top-item-top {
            display: flex;
            align-items: center;
            height: 38px;
            img {
              width: 22px;
              height: 22px;
            }
            p {
              font-family: "SourceHanSansCN-Medium";
              color: #7bc8d2;
              font-size: 11px;
              margin-left: 7px;
            }
          }
        }
      }
      // @media screen and (max-height: 758px) {
      //   .download-top-bottom-bottom {
      //     height: 184px !important;
      //   }
      // }
      .download-top-bottom-bottom {
        background-color: #fff;
        width: 470px;
        height: 214px;
        border-radius: 20px;
        box-shadow: 0px 7px 26px 6px rgba(123, 200, 210, 0.18);
        margin-top: 9px;
        display: flex;
        align-items: center;
        justify-content: center;
        .download-top-bottom-bottom-left {
          margin-right: 20px;
          // @media screen and (max-height: 758px) {
          //   img {
          //     width: 137px !important;
          //     height: 137px !important;
          //   }
          // }
          img {
            width: 167px;
            height: 167px;
            border-radius: 10px;
          }
        }
        .download-top-bottom-bottom-right {
          p {
            font-family: "SourceHanSansCN-Medium";
            color: #333333;
            font-size: 16px;
            letter-spacing: 2.25px;
            line-height: 26px;
          }
          // margin-bottom: 10px;
        }
      }

      .download-top-bottom-content {
        margin-top: 10px;
        width: 329px;
        height: 150px;
        border-radius: 10px;
        background-color: #fff;
        display: flex;
        // justify-content: space-around;
        // align-items: center;

        .download-top-bottom-content-left {
          margin-left: 64px;
          margin-top: 17px;
          img {
            width: 117px;
            height: 117px;
          }
        }

        .download-top-bottom-content-right {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          width: 80px;
          height: 30px;
          margin-left: 15px;
          margin-top: 60px;

          p {
            font-size: 11px;
            color: #333;
            font-family: SourceHanSansCN-Medium;
          }
        }
      }
    }
  }
}
.download-top-bottom1 {
  margin-left: 51.56%;
  background-color: #fff;
  width: 470px;
  height: 214px;
  border-radius: 20px;
  box-shadow: 0px 7px 26px 6px rgba(123, 200, 210, 0.18);
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  .download-top-bottom-bottom-left {
    margin-right: 30px;
    // @media screen and (max-height: 758px) {
    //   img {
    //     width: 137px !important;
    //     height: 137px !important;
    //   }
    // }
    img {
      width: 167px;
      height: 167px;
      border-radius: 10px;
    }
  }
  .download-top-bottom-bottom-right {
    display: flex;
    flex-direction: column;
    align-items: center;
    div {
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      img {
        width: 30px;
        height: 30px;
        margin-right: 10px;
      }
      p {
        font-size: 24px;
      }
    }
    p {
      font-family: "SourceHanSansCN-Medium";
      color: #7bc8d2;
      font-size: 18px;
      letter-spacing: 2.25px;
      line-height: 23px;
    }
    // margin-bottom: 10px;
  }
}
</style>